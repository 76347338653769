import {Redirect, Route} from 'react-router-dom';
import {
    IonAlert,
    IonApp, IonRouterOutlet, setupIonicReact
} from '@ionic/react';
import {IonReactHashRouter} from '@ionic/react-router';
import Users from './pages/Users';

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';

/* Theme variables */
import './theme/variables.css';

import './theme/main.scss'

import Page from "./pages/Page";
import LoginRequired from './components/LoginRequired';
import Profile from "./pages/Profile";
import ViewUser from "./pages/ViewUser";
import {useEffect, useState} from "react";
import {useSelector} from "react-redux";
import {RootState} from "./store";

setupIonicReact();

const updateServiceWorker = (serviceWorkerRegistration: ServiceWorkerRegistration) => {
    const registrationWaiting = serviceWorkerRegistration.waiting;
    if (registrationWaiting) {
        registrationWaiting.postMessage({ type: 'SKIP_WAITING' });
        registrationWaiting.addEventListener('statechange', (e: any) => {
            if (e.target.state === 'activated') {
                window.location.reload();
            }
        });
    }
};

const App: React.FC = () => {
    const isServiceWorkerUpdated = useSelector<RootState>((state) => state.worker.serviceWorkerUpdated);
    const serviceWorkerRegistration = useSelector<RootState>((state) => state.worker.serviceWorkerRegistration);
    const [showAlert, setShowAlert] = useState(false);
    useEffect(() => {
        if (isServiceWorkerUpdated) {
            setShowAlert(true);
        }
    }, [isServiceWorkerUpdated]);

    return (
        <IonApp>
            <IonAlert
                isOpen={showAlert}
                onDidDismiss={() => setShowAlert(false)}
                header={'App Update Available'}
                message={`An updated version of this app is available. Update now?`}
                buttons={[
                    {
                        text: 'No',
                        role: 'cancel',
                        cssClass: 'secondary',
                        handler: () => {
                            setShowAlert(false);
                        }
                    },
                    {
                        text: 'Yes',
                        handler: () => {
                            setShowAlert(false);
                            updateServiceWorker(serviceWorkerRegistration as ServiceWorkerRegistration);
                        }
                    }
                ]}
            />
            <LoginRequired>
                <IonReactHashRouter>
                    {/*<IonTabs>*/}
                        <IonRouterOutlet>
                            {/*<Route exact path="/services" render={(props) => {*/}
                            {/*    return <Page title="Services">*/}
                            {/*        <Services sendRequest={(() => {}) as any}/>*/}
                            {/*    </Page>*/}
                            {/*}}>*/}
                            {/*</Route>*/}
                            {/*<Route exact path="/services/:id" render={(props) => {*/}
                            {/*    return <Page backButtonUrl="/services" title={"View Service " + props.match.params.id}>*/}
                            {/*        <IonBreadcrumbs>*/}
                            {/*            <IonBreadcrumb href="#/services">Services</IonBreadcrumb>*/}
                            {/*            <IonBreadcrumb href={"#/services/" + props.match.params.id}>View Service {props.match.params.id}</IonBreadcrumb>*/}
                            {/*        </IonBreadcrumbs>*/}
                            {/*        <ViewService serviceId={+props.match.params.id} sendRequest={(() => {}) as any}/>*/}
                            {/*    </Page>;*/}
                            {/*}}>*/}
                            {/*</Route>*/}
                            <Route exact path="/profile" render={(props) => {
                                return <Page title="Profile" backButtonUrl={"/"}>
                                    <Profile />
                                </Page>
                            }}>
                            </Route>
                            <Route exact path="/users" render={(props) => {
                                return <Page title="Users">
                                    <Users />
                                </Page>
                            }}>
                            </Route>
                            <Route exact path="/users/:id" render={(props) => {
                                return <Page backButtonUrl="/" title="View User">
                                    {/*<IonBreadcrumbs>*/}
                                    {/*    <IonBreadcrumb href="#/users">Users</IonBreadcrumb>*/}
                                    {/*    <IonBreadcrumb href={"#/users/" + props.match.params.id}>View User {props.match.params.id}</IonBreadcrumb>*/}
                                    {/*</IonBreadcrumbs>*/}
                                    <ViewUser userId={+props.match.params.id} />
                                </Page>;
                            }}>
                            </Route>
                            <Route exact path="/">
                                <Redirect to="/users"/>
                            </Route>
                        </IonRouterOutlet>
                        {/*<IonTabBar slot="bottom">*/}
                        {/*    <IonTabButton tab="services" href="/services">*/}
                        {/*        <IonIcon icon={buildOutline}/>*/}
                        {/*        <IonLabel>Services</IonLabel>*/}
                        {/*    </IonTabButton>*/}
                        {/*    <IonTabButton tab="users" href="/users">*/}
                        {/*        <IonIcon icon={peopleOutline}/>*/}
                        {/*        <IonLabel>Users</IonLabel>*/}
                        {/*    </IonTabButton>*/}
                        {/*    /!*<IonTabButton tab="logs" href="/logs">*!/*/}
                        {/*    /!*    <IonIcon icon={newspaperOutline}/>*!/*/}
                        {/*    /!*    <IonLabel>Logs</IonLabel>*!/*/}
                        {/*    /!*</IonTabButton>*!/*/}
                        {/*</IonTabBar>*/}
                    {/*</IonTabs>*/}
                </IonReactHashRouter>
            </LoginRequired>
        </IonApp>
    );
}

export default App;
