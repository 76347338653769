import React, {Component} from "react";
import {
    IonIcon,
    IonItem, IonItemDivider,
    IonLabel,
    IonList, IonRefresher, IonRefresherContent,
    IonSearchbar,
    IonToggle,
    RefresherEventDetail, SearchbarInputEventDetail
} from "@ionic/react";
import {Access} from "../entities/Access";
import {User} from "../entities/User";
import {connect} from "react-redux";
import {clearAccesses, fetchAccesses, patchAccess} from "../reducers/accessesSlice";

import {documentLockOutline, hourglassOutline, alertCircleOutline} from "ionicons/icons";
import {withIonActionSheet} from "./withIonActionSheet";
import {withIonLoading} from "./withIonLoading";
import moment from "moment";
import {IonSearchbarCustomEvent} from "@ionic/core/dist/types/components";
import AwesomeDebouncePromise from 'awesome-debounce-promise';

interface AccessListProperties {
    user: User,
    accesses: Access[],
    fetchAccessesInProgress: boolean,
    requestsInProgress: boolean,
    fetchAccesses: any,
    patchAccess: any,
    clearAccesses: any,
    presentActionSheet: (actionSheetOptions: any) => any,
    presentLoading: (presentLoadingOptions: any) => void,
    dismissLoading: () => void
}

class AccessListState {
    refreshInterval: any | null = null;
    actionSheetPresented: boolean = false;
    loadingPresented: boolean = false;
    search: string | null | undefined;
    requestsCount: number = 0;
}

const permissionRepositoryTypeIcons: { [key: string]: string } = {
    'KeyVaultAccessPolicies': '/assets/Icons/security/10245-icon-service-Key-Vaults.svg',
    'SqlServer': '/assets/Icons/databases/02390-icon-service-Azure-SQL.svg',
}


class AccessList extends Component<AccessListProperties, AccessListState> {
    constructor(props: AccessListProperties) {
        super(props);

        this.props.clearAccesses();

        this.state = new AccessListState();
    }

    async fetchAccessesWithoutDebounce() {
        await this.props.fetchAccesses({userId: this.props.user.id, search: this.state.search}).unwrap();
    }

    debouncedFetchAccesses = AwesomeDebouncePromise(() => this.fetchAccessesWithoutDebounce(), 500);

    async fetchAccesses(required = false) {
        if (!required && this.state.requestsCount > 0) {
            return;
        }
        this.setState(state => {
            return {requestsCount: state.requestsCount + 1}
        })
        await this.debouncedFetchAccesses();
        this.setState(state => {
            return {requestsCount: state.requestsCount - 1}
        })
    }

    componentDidMount() {
        if (this.props.user != null) {
            this.fetchAccessesWithoutDebounce();

            this.setState({
                refreshInterval: setInterval(() => {
                    if (!this.props.requestsInProgress && !this.state.actionSheetPresented && !this.state.loadingPresented) {
                        this.fetchAccesses();
                    }
                }, 10000)
            });
        }
    }

    componentWillUnmount() {
        if (this.state.refreshInterval != null) {
            clearInterval(this.state.refreshInterval);
            this.setState({refreshInterval: null});
        }
    }

    async refresh(event: CustomEvent<RefresherEventDetail>) {
        await this.fetchAccesses(true);
        event.detail.complete();
    }

    renderModifiedExternallyTag(access: Access) {
        if (!access.modifiedExternally) return <></>
        return <p style={{
            display: 'flex', flexDirection: 'row',
            alignItems: 'center'
        }}>
            <IonIcon style={{marginRight: '5px'}} icon={alertCircleOutline}></IonIcon>
            <span>Modified externally {moment.utc(access.updateDate).fromNow()}</span>
        </p>


    }

    getExpiresString(revokeAt: moment.Moment) {
        return revokeAt > moment.utc() ? 'Expires' : 'Should have been expired';
    }


    renderExpiredTag(access: Access) {
        if (!access.modifiedProgrammatically && access.revokeAt == null) return <></>
        return <p style={{
            display: 'flex', flexDirection: 'row',
            alignItems: 'center'
        }}>
            <IonIcon style={{marginRight: '5px'}} icon={hourglassOutline}></IonIcon>
            <span>{access.revokeAt == null
                ? `Expired ${moment.utc(access.revokedAt).fromNow()}`
                : `${this.getExpiresString(moment.utc(access.revokeAt))} ${moment.utc(access.revokeAt).fromNow()}`}</span>
        </p>
    }

    getEntityIcon(access: Access) {
        if (access.permissionRepositoryType === "AspNetUserClaims")
            return <IonIcon slot="start" style={{fontSize: '24px'}} icon={documentLockOutline}></IonIcon>;
        return <IonIcon slot="start" style={{fontSize: '24px'}} src={permissionRepositoryTypeIcons[access.permissionRepositoryType]}></IonIcon>
    }

    entityInfoTemplate(access: Access) {
        return (
            <IonLabel>
                {this.getEntityIcon(access)}
                <h3>{access.service?.serviceName}</h3>
                <p>{access.service?.serviceAdditionalInformation}</p>
                {this.renderExpiredTag(access)}
                {this.renderModifiedExternallyTag(access)}
            </IonLabel>)
    }

    handleSearch(ev: IonSearchbarCustomEvent<SearchbarInputEventDetail>) {
        this.setState({search: ev.detail.value})
        setTimeout(() => this.fetchAccesses(true));
    }

    render() {
        if (this.props.user == null) {
            return '';
        }

        return (
            <>
                <IonRefresher slot="fixed" onIonRefresh={this.refresh.bind(this)}>
                    <IonRefresherContent></IonRefresherContent>
                </IonRefresher>
                <IonItemDivider sticky className="access-list-search-bar">
                    <IonSearchbar
                        debounce={1000}
                        mode="ios"
                        enterkeyhint="search" inputmode="search" onIonInput={(ev) => this.handleSearch(ev)}></IonSearchbar>
                </IonItemDivider>
                <IonList>
                    {(!this.props.fetchAccessesInProgress && this.props.accesses.length === 0)
                        ? <IonItem lines="none" className={"ion-text-center"}><IonLabel className={"ion-color-secondary"}>No accesses available</IonLabel></IonItem>
                        : ''}

                    {this.props.accesses.map((access, index) => (
                        <IonItem lines={index === this.props.accesses.length - 1 ? "none" : "inset"} key={index}>
                            {this.entityInfoTemplate(access)}
                            <IonToggle disabled={access.isReadOnly} slot="end" checked={access.enabled} onClick={(e) => e.stopPropagation()}
                                       onIonChange={async (e) => {
                                           if (e.detail.checked === access.enabled) return;

                                           if (!e.detail.checked) {
                                               this.setState({loadingPresented: true})
                                               this.props.presentLoading({message: 'Revoking access'})
                                               try {
                                                   await this.props.patchAccess({
                                                       userId: this.props.user.id,
                                                       serviceId: access.serviceId,
                                                       applyPatch: (access: Access) => {
                                                           access.enabled = e.detail.checked;
                                                           access.revokeAt = null;
                                                       }
                                                   }).unwrap();
                                               } catch (error) {
                                                   e.target.checked = !e.detail.checked;
                                               }
                                               this.props.dismissLoading()
                                               this.setState({loadingPresented: false})
                                               return;
                                           }

                                           const handler = async (result: any) => {
                                               this.setState({loadingPresented: true})
                                               this.props.presentLoading({message: 'Granting access'})
                                               try {
                                                   await this.props.patchAccess({
                                                       userId: this.props.user.id,
                                                       serviceId: access.serviceId,
                                                       applyPatch: (access: Access) => {
                                                           access.enabled = e.detail.checked;
                                                           access.revokeAt = result === 'forever'? null : moment().utc().add(...result).toISOString();
                                                       }
                                                   }).unwrap();
                                               } catch (error) {
                                                   e.target.checked = !e.detail.checked;
                                               }

                                               this.props.dismissLoading()
                                               this.setState({loadingPresented: false})
                                               this.setState({actionSheetPresented: false})
                                           }

                                           this.setState({actionSheetPresented: true})
                                           this.props.presentActionSheet({
                                               header: 'Access duration',
                                               buttons: [
                                                   {
                                                       text: 'Forever',
                                                       role: 'destructive',
                                                       handler: () => handler('forever'),
                                                   },
                                                   {
                                                       text: '1 minute',
                                                       handler: () => handler([1, 'minutes']),
                                                   },
                                                   {
                                                       text: '5 minutes',
                                                       handler: () => handler([5, 'minutes'])
                                                   },
                                                   {
                                                       text: '1 hour',
                                                       handler: () => handler([1, 'hours'])
                                                   },
                                                   {
                                                       text: '2 hours',
                                                       handler: () => handler([2, 'hours'])
                                                   },
                                                   {
                                                       text: '1 day',
                                                       handler: () => handler([1, 'days'])
                                                   },
                                                   {
                                                       text: '1 week',
                                                       handler: () => handler([1, 'weeks'])
                                                   },
                                                   {
                                                       text: 'Cancel',
                                                       role: 'cancel',
                                                       handler: () => {
                                                           this.setState({actionSheetPresented: false})
                                                           e.target.checked = !e.detail.checked
                                                       }
                                                   },
                                               ]
                                           });
                                       }} />
                        </IonItem>
                    ))}
                </IonList>
                {/*<AccessEditModal*/}
                {/*    access={(this.state.accessIndex == null ? null : this.props.accesses[this.state.accessIndex])}*/}
                {/*    updateAccess={(access) => {*/}
                {/*        this.props.updateAccesses(produce(this.props.accesses, draft => {*/}
                {/*            draft[this.state.accessIndex!] = access;*/}
                {/*        }));*/}
                {/*    }}*/}
                {/*    saveAccess={async (callback) => {*/}
                {/*        await this.saveAccess(this.state.accessIndex!, callback);*/}
                {/*    }}*/}
                {/*    onDidDismiss={() => this.setState({accessIndex: null})}*/}
                {/*/>*/}
            </>
        )
    }
}

const mapStateToProps = (state: any) => ({
    user: state.user,
    accesses: state.accesses,
    fetchAccessesInProgress: state.requestStatus.fetchAccessesRequestsInProgress > 0,
    requestsInProgress: state.requestStatus.requestsInProgress > 0,
});

const mapDispatchToProps = {patchAccess, fetchAccesses, clearAccesses};

export default connect(mapStateToProps, mapDispatchToProps)(withIonLoading(withIonActionSheet(AccessList)));
