import React from "react";
import {useIonToast} from "@ionic/react";

export const ErrorToast: React.FC<{error: string | null}> = params => {
    const [present, dismiss] = useIonToast();

    if (params.error != null) {
        present({
            message: "Error: " + params.error,
            color: "danger",
            duration: 3000,
        })
    }

    if (params.error == null) {
        dismiss();
    }

    return <></>
};
