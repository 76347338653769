import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import axios from "axios";
import {Access} from "../entities/Access";
import { produce } from "immer";
import {compare} from "fast-json-patch";
import _ from 'lodash'
import {getToken} from "../repositories/TokenRepository";

const initialState: Access[] = [];

export const fetchAccesses = createAsyncThunk('accesses/fetchAccesses', async (params: any, {rejectWithValue}) => {
    try {
        const response = await axios.get(process.env.REACT_APP_API_URL + '/access/' + params.userId, {
            headers: {
                'Api-Key': `Bearer ${getToken()}`
            },
            params: {
                'search': params.search
            }
        });
        return response.data;
    } catch (error) {
        return rejectWithValue(error);
    }
})


export const patchAccess = createAsyncThunk('accesses/patchAccess', async (params: any, {getState, rejectWithValue}) => {
    const state: any = getState();

    const accessIndex = _.findIndex(state.accesses, {userId: params.userId, serviceId: params.serviceId})

    const initialAccess = produce(state.accesses[accessIndex], () => {});
    const modifiedAccess = produce(state.accesses[accessIndex], params.applyPatch);

    const patch = compare(initialAccess as Object, modifiedAccess as Object);

    try {
        const response = await axios.patch(`${process.env.REACT_APP_API_URL}/access/${params.userId}/${params.serviceId}`, patch, {
            headers: {
                'Api-Key': `Bearer ${getToken()}`
            }
        });
        return response.data;
    } catch (error) {
        return rejectWithValue(error);
    }

})

const accessesSlice = createSlice({
    name: 'accesses',
    initialState,
    reducers: {
        clearAccesses: () => {
            return [];
        }
    },
    extraReducers(builder) {
        builder.addCase(fetchAccesses.fulfilled, (state, action) => {
            return action.payload;
        })
        builder.addCase(patchAccess.fulfilled, (state, action) => {
            var index = _.findIndex(state, {userId: action.payload.userId, serviceId: action.payload.serviceId})
            state.splice(index, 1, action.payload);
        })
    }
})

export const {clearAccesses} = accessesSlice.actions;

export default accessesSlice.reducer
