import React, {useEffect, useState} from "react";
import {
    IonButton,
    IonInput, IonItem,
    IonList, IonProgressBar,
    useIonToast
} from "@ionic/react";
import {useDispatch, useSelector} from "react-redux";
import {AppDispatch, RootState} from "../store";
import {authenticateTotp} from "../reducers/authenticatedSlice";
import moment from "moment";


export const TotpLogin: React.FC = () => {
    const [present] = useIonToast();
    const dispatch = useDispatch<AppDispatch>();
    const countBack = useSelector<RootState>(state => state.authenticated.countBack);
    const [authCode, setAuthCode] = useState('')

    const [currentTime, setCurrentTime] = useState(moment().unix());

    useEffect(() => {
        let secTimer = setInterval( () => {
            console.log(moment().unix(), countBack);
            if (countBack != null) {
                setCurrentTime(moment().unix());
            }
        },1000)

        return () => clearInterval(secTimer);
    });

    const handleLogin = async () => {
        dispatch(authenticateTotp(authCode)).unwrap().catch(() => {
            console.log("error");
            present({
                message: "Invalid code",
                color: "danger",
                duration: 3000,
            })
        });
    }

    return <div className={"vertical-center"}>
        <IonList lines={"none"} className={"ion-text-center"}>
            <IonItem lines={"inset"}>
                <IonInput autofocus style={{'textAlign': 'center'}} type="text" value={authCode}
                          onIonInput={(e) => {
                              setAuthCode(e.detail.value!);
                          }}
                          onKeyDown={event => {
                              if (event.key === 'Enter') {
                                  handleLogin()
                              }
                          }}
                          placeholder="Enter code" />
            </IonItem>
            {countBack != null ? <IonProgressBar value={(currentTime - (countBack as number)) / 10} /> : ''}
            <IonButton disabled={countBack != null} className={"ion-margin-top"} color="primary" onClick={handleLogin}>Login</IonButton>
        </IonList>
    </div>

};
