import {createSlice, PayloadAction} from '@reduxjs/toolkit'
import {fetchUser} from "./userSlice";
import {fetchUsers} from "./usersSlice";
import {fetchAccesses, patchAccess} from "./accessesSlice";

const initialState = {
    requestsInProgress: 0,
    fetchAccessesRequestsInProgress: 0,
    logoutNeeded: false,
    error: null,
};

const onRejected = (state: any, action: any) => {
    console.log(action);

    if (typeof action.payload !== 'undefined' && typeof action.payload.response !== 'undefined') {
        if (action.payload.response.status == 401 || action.payload.response.status == 403) {
            state.logoutNeeded = true;
        }
    }
}

const requestStatusSlice = createSlice({
    name: 'requestStatus',
    initialState,
    reducers: {
        setLogoutNeeded: (state, action: PayloadAction<boolean>) => {
            state.logoutNeeded = action.payload;
        },
    },
    extraReducers(builder) {
        builder.addCase(fetchUsers.pending, (state: any, action) => {
            state.requestsInProgress++;
        });
        builder.addCase(fetchUsers.rejected, (state: any, action) => {
            state.requestsInProgress--;
            state.error = (action.payload as any).message;
            onRejected(state, action);
        });
        builder.addCase(fetchUsers.fulfilled, (state: any, action) => {
            state.requestsInProgress--;
            state.error = null;
        });

        builder.addCase(fetchUser.pending, (state: any, action) => {
            state.requestsInProgress++;
        });
        builder.addCase(fetchUser.rejected, (state: any, action) => {
            state.requestsInProgress--;
            state.error = (action.payload as any).message;
            onRejected(state, action);
        });
        builder.addCase(fetchUser.fulfilled, (state: any, action) => {
            state.requestsInProgress--;
            state.error = null;
        });

        builder.addCase(fetchAccesses.pending, (state: any, action) => {
            state.requestsInProgress++;
            state.fetchAccessesRequestsInProgress++;
        });
        builder.addCase(fetchAccesses.rejected, (state: any, action) => {
            state.error = (action.payload as any).message;
            state.fetchAccessesRequestsInProgress--;
            state.requestsInProgress--;
            onRejected(state, action);
        })
        builder.addCase(fetchAccesses.fulfilled, (state: any, action) => {
            state.requestsInProgress--;
            state.fetchAccessesRequestsInProgress--;
            state.error = null;
        });

        builder.addCase(patchAccess.pending, (state: any, action) => {
            state.requestsInProgress++;
        });
        builder.addCase(patchAccess.rejected, (state: any, action) => {
            state.error = (action.payload as any).message;
            state.requestsInProgress--;
            onRejected(state, action);
        })
        builder.addCase(patchAccess.fulfilled, (state: any, action) => {
            state.requestsInProgress--;
            state.error = null;
        });
    }
})

export const {setLogoutNeeded} = requestStatusSlice.actions;

export default requestStatusSlice.reducer
