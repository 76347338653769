import {Component, ReactNode} from "react";
import {auth, signInWithGoogle} from "../firebase";
import {withAuth} from "./withAuth";
import {AuthStateHook} from "react-firebase-hooks/auth/dist/auth/useAuthState";
import {IonButton, IonProgressBar} from "@ionic/react";
import {authenticate} from "../reducers/authenticatedSlice";
import {connect } from "react-redux";

import './LoginRequired.css'
import {AuthenticationErrorToast} from "./AuthenticationErrorToast";
import {TotpLogin} from "./TotpLogin";
import {TotpRegister} from "./TotpRegister";

interface ComponentProps {
    children: ReactNode,
    authState: AuthStateHook,
    authenticated: boolean,
    totpLoginNeeded: boolean,
    authenticate: any,
    authenticationError: string | null,
    totpUri: string | null
}

let interval: NodeJS.Timeout | null = null;

class LoginRequired extends Component<ComponentProps> {

    async componentDidMount() {
        console.log("LoginRequired.componentDidMount");
        auth.onAuthStateChanged((user) => {
            if (interval != null) {
                clearInterval(interval);
                interval = null;
            }

            if (user == null) {
                return;
            }

            this.props.authenticate(user).unwrap().catch(() => {
                interval = setInterval(async () => {
                    await this.props.authenticate(user).unwrap();
                    if (interval != null) {
                        clearInterval(interval);
                        interval = null;
                    }
                }, 2000);
            });
        })
    }

    render() {
        const [user, loading, error] = this.props.authState;

        if (typeof error !== 'undefined') {
            return <div className="vertical-center">
                <div>Error {error.message}</div>
                <IonButton onClick={() => signInWithGoogle()}>Login with Google</IonButton>
            </div>
        }

        if (user == null && !loading) {
            return <div className="vertical-center">
                <IonButton onClick={() => signInWithGoogle()}>Login with Google</IonButton>
            </div>
        }

        if (this.props.totpLoginNeeded) {
            return <TotpLogin />
        }

        if (this.props.totpUri != null) {
            return <TotpRegister />
        }

        if (!this.props.authenticated) {
            return <div className="vertical-center">
                <div className="vertical-align-middle">
                    <div className="login-wait-message">Please wait...</div>
                    <IonProgressBar type="indeterminate"></IonProgressBar>
                    <AuthenticationErrorToast error={this.props.authenticationError} />
                </div>
            </div>
        }

        return this.props.children
    }
}

const mapStateToProps = (state: any) => ({
    authenticated: state.authenticated.value,
    totpLoginNeeded: state.authenticated.totpLoginNeeded,
    authenticationError: state.authenticated.error,
    totpUri: state.authenticated.totpUri,

});

const mapDispatchToProps = {authenticate};

export default withAuth(connect(mapStateToProps, mapDispatchToProps)(LoginRequired));

